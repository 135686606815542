import type { JSX } from 'react';
import { SliderReviews } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type ReviewsProps = SliceComponentProps<Content.ReviewsSlice>;

const Reviews = ({ slice }: ReviewsProps): JSX.Element => (
  <SliderReviews {...slice} />
);

export default Reviews;
